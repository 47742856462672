// Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Fontawesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";

// Animate.css
@import "~animate.css/source/animate.css";

.main_menu {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fff;
    width: 100%;
    z-index: 999;
}

.main_menu .main-menu-item ul li .nav-link {
    color: #000;
    font-size: 1em;
    margin: 4px 18px;
    border-bottom: 2px solid #fff;
    font-family: "Roboto", sans-serif;
    transition: all .5s ease-in-out;
}

.main_menu .main-menu-item ul li .active {
    color: $red !important;
    border-bottom: 2px solid $red;
}

.main_menu .main-menu-item ul li .nav-link:hover {
    color: $red;
    border-bottom: 2px solid $red;
}

.menu_fixed  .main-menu-item ul li .nav-link:hover {
    color: $red;
}

.dropdown .dropdown-menu {
    transition: all 0.5s;
    margin-top: 25px;
    border: none;
}

.main_menu .navbar {
    padding: 20px 0px;
}

.menu_fixed {
    position: fixed;
    z-index: 9999 !important;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
    top: 0;
}

.bg-wrapper{
    position: relative;
    z-index: 1;
    background-image: url('../../public/images/background.jpeg');
    background-position: center center;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
}
.bg-wrapper::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    z-index: -1;
}

@media (max-width: 992px) {
    .main_menu .main-menu-item ul li .nav-link {
        padding: 8px 0px;
    }
}


@media (max-width: 767.98px) {
    .bg-wrapper{
        background-position: center right;
    }
}

.single_special_cource{
    height: 100%;
}

.single_special_cource img{
    width: 100%;
}

.section_tittle p {
    color: #556172;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 11px;
}

.section_tittle h2 {
    font-size: 42px;
    color: #0c2e60;
    line-height: 37px;
    font-weight: 700;
    position: relative;
}

.section_tittle h2:after {
    position: absolute;
    content: "";
    bottom: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    width: 80px;
    height: 3px;
    background-color: $red;
}

.special_cource_text {
    cursor: pointer;
    padding: 35px 35px 35px;
}

.special_cource_text .link{
    text-decoration: none;
    color: #0c2e60;
}
.special_cource_text .link:hover{
    text-decoration: none;
    color: $red;
}

.special_cource_text .price{
    color: #0c2e60;
}
